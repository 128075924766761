import React, {createContext, useContext, useEffect, useState} from 'react';
import {
  CERT_TEMPLATE_VERSIONS,
  COMPANY_TYPES,
  CONSENTS,
  CONSENT_VERSIONS,
} from 'configs';
import {get, isEmpty, isObject, keys, merge, pick, omit, has} from 'lodash';
import Cookies from 'utils/cookies';
import {login, logout, getAuthSession, loginOAuth, post2FA} from 'api/auth';
import cleanDeep from 'clean-deep';

import {useConsent} from './ConsentContext';
import DataLayerPush from 'utils/gtm';
import {removeItem} from 'utils/localStorage';
import {CB_TEMPLATE_VERSIONS} from 'configs';

export const AuthContext = createContext({});
export const AuthConsumer = AuthContext.Consumer;

const MAIN_ACCOUNTS = omit(COMPANY_TYPES, ['ab', 'cb', 'mncb', 'ce']);
const DC_ACCOUNTS = omit(COMPANY_TYPES, ['company']);

const ACCOUNT_DATA = [
  'account_id',
  'company_id',
  'account_email',
  'account_first_name',
  'account_last_name',
  'account_role',
  'account_status',
  'account_image',
  'account_confirm_email',
  'account_confirm_mobile',
  'account_occupations_name',
  'entity_id',
  'company.company_id',
  'company.company_name',
  'company.company_acronym',
  'company.company_type',
  'company.is_default',
  'company.template_version',
  'company.signed_cb_data_management_agreement',
  'account_detail.data_management.hide_verify_data_notice',
  'account_detail.data_management.hide_new_data_import_process_intro',
];

export function validateConsents(companyType, consents = {}, isDefault) {
  if (isEmpty(consents) || !isObject(consents)) return null;

  let consentsByCompany;

  const isAB = companyType === COMPANY_TYPES.ab;
  const isCB = companyType === COMPANY_TYPES.cb;
  const isCE = companyType === COMPANY_TYPES.ce;

  if (isAB) {
    consentsByCompany = pick(CONSENT_VERSIONS, [
      CONSENTS.terms,
      CONSENTS.privacy,
    ]);
  } else if (isCB) {
    consentsByCompany = pick(CONSENT_VERSIONS, [
      CONSENTS.terms,
      CONSENTS.privacy,
      CONSENTS.data,
    ]);

    if (!isDefault) {
      consentsByCompany = pick(CONSENT_VERSIONS, [
        CONSENTS.terms,
        CONSENTS.privacy,
      ]);
    }
  } else if (isCE) {
    consentsByCompany = pick(CONSENT_VERSIONS, [
      CONSENTS.terms,
      CONSENTS.privacy,
      CONSENTS.qt,
    ]);
  } else {
    consentsByCompany = pick(CONSENT_VERSIONS, [
      CONSENTS.terms,
      CONSENTS.privacy,
    ]);
  }

  return keys(consentsByCompany).every((item) => {
    let validConsent, validStatus, validVersion;

    // QT Consent is optional.
    if (item === CONSENTS.qt) {
      validConsent = consents.hasOwnProperty(item);
      validStatus = true;

      // Check version, when status is 1 only.
      if (validConsent && consents[item].status === 1) {
        validVersion =
          validConsent &&
          parseFloat(consents[item].version) ===
            parseFloat(CONSENT_VERSIONS[item]);
      } else {
        validVersion = true;
      }
    } else {
      validConsent = consents.hasOwnProperty(item);
      validStatus = validConsent && consents[item].status === 1;
      validVersion =
        validConsent &&
        parseFloat(consents[item].version) ===
          parseFloat(CONSENT_VERSIONS[item]);
    }

    return validConsent && validStatus && validVersion;
  });
}

export function validateConsent(consents = {}) {
  if (isEmpty(consents) || !isObject(consents)) return null;
  return consents;
}

function validateToken(token = '') {
  if (isEmpty(token)) return null;
  return token;
}

function validateAccount(account = {}) {
  if (isEmpty(account) || !isObject(account)) return null;
  return pick(account, ACCOUNT_DATA);
}

function parseSession(token, account, consent) {
  return cleanDeep({
    token: validateToken(token),
    account: validateAccount(account),
    consent: validateConsent(consent),
  });
}

export function AuthProvider(props) {
  const {children} = props;
  const {acceptConsent, consentBanner} = useConsent();
  const {eventAuth} = DataLayerPush;
  const [auth, setAuth] = useState(generateAuth());

  const {isAuth} = auth || {};

  useEffect(() => {
    const {token} = auth;
    if (token) {
      void handleLoginBySession(token);
    }
  }, []);

  useEffect(() => {
    // if the consent is accepted then we are going to fire the event auth checker
    if (!consentBanner) eventAuth();
  }, [isAuth, consentBanner]);

  function generateAuth() {
    const session = getSession();

    const {token, account, consent, isAuth} = session || {};
    const companyType = get(account, 'company.company_type', '');
    // const isAuth = !isEmpty(session);
    const isDefault = get(account, 'company.is_default', 0) === 1;
    const isConsented =
      isAuth && !!validateConsents(companyType, consent, isDefault);

    return {
      isAuth: isAuth,
      isConsented: isConsented,
      isDefault: isDefault,
      token: token,
      account: account,
      consent: consent,
      companyType: companyType,
    };
  }

  function getSession() {
    const session = Cookies.session || {};

    const {token, account, consent} = session;
    const companyType = get(account, 'company.companyType', '');

    const isAuth = !isEmpty(session);
    const isDefault = get(account, 'company.is_default', 0) === 1;
    const isConsented =
      isAuth && !!validateConsents(companyType, consent, isDefault);

    return {
      isAuth: isAuth,
      isConsented: isConsented,
      isDefault: isDefault,
      token: token,
      account: account,
      consent: consent,
      companyType: companyType,
    };
  }

  function setSession(data) {
    let session = '';

    if (!isEmpty(data) && isObject(data)) {
      const {token, account, consent} = data;
      session = parseSession(token, account, consent);
    }

    // Accept Cookie Consent
    acceptConsent();
    // Replace Browser Cookie
    Cookies.session = session;

    // Replace Auth states
    const auth = generateAuth();
    setAuth({...auth});
  }

  function updateSession(data) {
    let session = '';
    if (!isEmpty(data) && isObject(data)) {
      const {token, account, consent} = data;

      const newSession = parseSession(token, account, consent);

      const oldSession = getSession();
      session = merge({}, oldSession, newSession);
    }

    // Replace Browser Cookie
    Cookies.session = session;

    const auth = generateAuth();
    setAuth(auth);
  }

  async function handleLogin(
    email,
    password,
    remember = false,
    secret,
    eg_code
  ) {
    try {
      const {data} = await login({email, password, remember, secret, eg_code});

      const {session_id, account, consent} = data;

      setSession({
        token: session_id,
        account: account,
        consent: consent,
      });

      return Promise.resolve(data);
    } catch (e) {
      throw e;
    }
  }

  async function handle2FA({token, secret, eg_code}) {
    try {
      const {data} = await post2FA({token, secret, eg_code});
      const {session_id, account, consent} = data;

      setSession({
        token: session_id,
        account: account,
        consent: consent,
      });

      return Promise.resolve(data);
    } catch (e) {
      throw e;
    }
  }

  async function handleLoginBySession(sessionId) {
    try {
      const {data} = await getAuthSession(sessionId);
      const {account, consent} = data;

      setSession({
        token: sessionId,
        account: account,
        consent: consent,
      });

      return Promise.resolve(data);
    } catch (e) {
      throw e;
    }
  }

  async function handleOAuth({
    email,
    password,
    remember = false,
    secret,
    app_id,
  }) {
    try {
      const {data} = await loginOAuth({
        email,
        password,
        remember,
        secret,
        app_id,
      });
      const {session_id, account, consent} = data;

      setSession({
        token: session_id,
        account: account,
        consent: consent,
      });

      return Promise.resolve(data);
    } catch (e) {
      throw e;
    }
  }

  async function handleLogout() {
    try {
      await logout();
      setSession(null);

      removeItem('dontShowReleaseNotes');
      await new Promise((resolve) => setTimeout(resolve, 500)); // Freeze
      return Promise.resolve(true);
    } catch (e) {
      throw e;
    }
  }

  function getCBTemplateVersion() {
    const {company} = account || {};
    const {template_version} = company || {};
    const {cb} = template_version || {};
    let cbVersion = null;

    switch (companyType) {
      case COMPANY_TYPES.ab: {
        cbVersion = CB_TEMPLATE_VERSIONS[parseInt(cb)];
        break;
      }
      case COMPANY_TYPES.mncb: {
        cbVersion = CB_TEMPLATE_VERSIONS[2];
        break;
      }
      default: {
        cbVersion = null;
      }
    }

    return cbVersion;
  }

  function getCertTemplateVersion(account) {
    const {company} = account || {};
    const {template_version} = company || {};
    const {cert} = template_version || {};

    let certVersion = null;

    switch (companyType) {
      case COMPANY_TYPES.ab:
      case COMPANY_TYPES.cb:
      case COMPANY_TYPES.mncb: {
        certVersion = CERT_TEMPLATE_VERSIONS[parseInt(cert)];
        break;
      }

      case COMPANY_TYPES.ce: {
        certVersion = CERT_TEMPLATE_VERSIONS[2];
        break;
      }

      default: {
        certVersion = null;
      }
    }

    return certVersion;
  }

  const {isConsented, isDefault, token, account, companyType, consent} = auth;

  const isDCAccount = has(DC_ACCOUNTS, companyType);
  const isMainAccount = has(MAIN_ACCOUNTS, companyType);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        cbTemplate: getCBTemplateVersion(account),
        certTemplate: getCertTemplateVersion(account),
        isConsented,
        isDefault,
        token,
        account,
        companyType,
        isDCAccount,
        isMainAccount,
        consent,
        handleLogin,
        handle2FA,
        handleOAuth,
        handleLoginBySession,
        handleLogout,
        getSession,
        setSession,
        updateSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function withAuth(Component) {
  return function (props) {
    const auth = useContext(AuthContext);

    return <Component auth={auth} {...props} />;
  };
}

export const useAuth = () => useContext(AuthContext);
