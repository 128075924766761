import React, {useState, useEffect, useContext} from 'react';
import {useAuth} from 'contexts/AuthContext';
import Cookies from 'utils/cookies';
import {CookieConsent} from 'components/CookieConsent';
import DataLayerPush from 'utils/gtm';
import {isUndefined} from 'lodash';

// previous implementation
// we set everything up thru useEffect every time the isAuth changes we run auth event
// current implementation
// we set the setting of showing of banner every action for example e.g login, register or logout
// Notes
// Unauthenticated user - relies on click of consent banner to be tracked
// Authenticated user - automatically tracked

const ConsentContext = React.createContext({});

function ConsentProvider(props) {
  const {children} = props;
  const {isAuth} = useAuth();
  const hasCookieConsent = !isUndefined(Cookies.consent);
  const [consentBanner, setConsentBanner] = useState(
    !hasCookieConsent && !isAuth
  );
  const {eventAuth} = DataLayerPush;

  useEffect(() => {
    // if the consent is accepted then we are going to fire the event auth checker
    if (!consentBanner) eventAuth();
  }, [isAuth, consentBanner]);

  function setCookie(value) {
    // this won't re set the cookie if cookie consent is already accepted
    if (!Cookies.consent) Cookies.consent = value;
  }

  function hideConsent(value) {
    setCookie(value);
    setConsentBanner(false);
  }

  function acceptConsent() {
    hideConsent(true);
  }

  function declineConsent() {
    hideConsent(false);
  }

  return (
    <ConsentContext.Provider
      value={{consentBanner, acceptConsent, declineConsent}}
    >
      {children}
      <CookieConsent
        visible={consentBanner}
        onAccept={acceptConsent}
        onDecline={declineConsent}
      />
    </ConsentContext.Provider>
  );
}

const useConsent = () => useContext(ConsentContext);

export {ConsentContext, ConsentProvider, useConsent};
