import React from 'react';
import {Redirect} from 'react-router-dom';

import Error from 'views/errors';

import abProfile from './ab-profile';
import branches from './branches';
import cbProfile from './cb-profile';
import ceProfile from './ce-profile';
import certificationBodies from './certification-bodies';
import certifications from './certifications';
import certifiedEntities from './certified-entities';
// import certifiedMarketplace from './certified-marketplace';
import consents from './consents';
import dashboard from './dashboard';
import enquiries from './enquiries';
import logout from './logout';
import marks from './marks';
import settings from './settings';
import setup from './setup';
import users from './users';
import importManagement from './import-management';
import importSettings from './import-settings';
import cbUpload from './cb-upload';
import standards from './standards';
import abStandards from './ab-standards';
import cbStandards from './cb-standards';

import {DOCUMENTATION_LANDING_URL} from 'configs';
import analytics from './analytics';

/*
Example:
{
env: ROUTE_ENV_RULES.development,
access: ROUTE_ACCESS_RULES.public,
...certification,
},
*/

const Replace = ({to}) => {
  React.useEffect(() => {
    window.location.replace(to);
  }, []);
  return null;
};

const routes = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/dashboard" />,
  },
  abProfile,
  standards,
  abStandards,
  cbStandards,
  branches,
  cbProfile,
  ceProfile,
  certificationBodies,
  certifications,
  certifiedEntities,
  consents,
  dashboard,
  enquiries,
  logout,
  marks,
  settings,
  setup,
  users,
  importManagement,
  importSettings,
  cbUpload,
  analytics,
  {
    path: '/user-guide',
    component: () => <Replace to={DOCUMENTATION_LANDING_URL} />,
  },
  {
    exact: true,
    path: '/error/:code',
    component: Error,
  },
  {
    exact: true,
    path: '/not-found',
    component: Error,
  },
  {
    component: () => <Redirect to="/not-found" />,
  },
].filter((v) => v);

export default routes;
